import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import Obfuscate from 'react-obfuscate';

import Container from './container';
import Facebook from '../images/inline/fb.svg';
import Instagram from '../images/inline/ig.svg';
import YouTube from '../images/inline/yt.svg';

const StyledFooter = styled.footer`
  padding: 3rem 0;
  background-color: #090720;
  color: #FFF;
`;

const StyledObfuscate = styled(Obfuscate)`
  color: #FFF;
  font-size: 2rem;
  text-decoration: none;
  margin-bottom: 0.5rem;
`;

const NetworkLink = ({ href, children }) => (
  <Box mx={2}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  </Box>
);

const Copyright = styled.div`
  font-weight: 400;
  line-height: 1.75;
  text-align: center;
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialNetworks {
            fb
            ig
            yt
          }
        }
      }
      wordpressAcfOptions {
        options {
          fakturacni_udaje_v_paticce
        }
      }
    }
  `);
  const { fb, ig, yt } = data.site.siteMetadata.socialNetworks;
  const { fakturacni_udaje_v_paticce: udajePaticka } = data.wordpressAcfOptions.options;

  return (
    <StyledFooter>
      <Container>
        <Flex width="100%" flexDirection={['column', 'row']} justifyContent={['center', 'space-around']} alignItems={['center']} mb={4}>
          <StyledObfuscate tel="+420732745312">+420 732 745 312</StyledObfuscate>
          <StyledObfuscate email="info@burysax.cz" />
        </Flex>

        <Flex width="100%" justifyContent="center" mb={4}>
          <NetworkLink href={fb}>
            <Facebook />
          </NetworkLink>
          <NetworkLink href={ig}>
            <Instagram />
          </NetworkLink>
          <NetworkLink href={yt}>
            <YouTube />
          </NetworkLink>
        </Flex>

        {udajePaticka && (
        <Copyright>
          <small>Copyright © 2019 Burysax</small>
        </Copyright>
        )}
      </Container>
    </StyledFooter>
  );
};

export default Footer;
