import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';

const StyledContainer = styled(Flex)`
  max-width: 640px;
  margin: 0 auto;
`;

const Container = (props) => (
  <StyledContainer flexDirection="column" px={3}>
    {props.children}
  </StyledContainer>
);

export default Container;
